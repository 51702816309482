<template>
  <CRow>
    <CCol col="12" xl="8">
      <transition name="slide">
      <CCard>
        <CCardHeader>
          Users
          <CButton size="sm" style="float:right;" color="success" @click="addModal = true">Add Ship To</CButton>
        </CCardHeader>
        <CCardBody>
          <CAlert
            :show.sync="dismissCountDown"
            color="primary"
            fade
          >
            ({{dismissCountDown}}) {{ message }}
          </CAlert>
          <CDataTable
            :items="items"
            :fields="fields"
            :items-per-page="10"
            pagination
          >
          <template #edit="{item}">
            <td>
              <CButton size="sm" color="primary" @click="editShipTo( item )">Edit</CButton>
            </td>
          </template>
          <template #delete="{item}">
            <td>
              <CButton size="sm" v-if="you!=item.id" color="danger" @click="deleteUser( item.id )">Delete</CButton>
            </td>
          </template>
        </CDataTable>
        </CCardBody>
      </CCard>
      </transition>

      <CModal
        title="Ship To"
        :show.sync="addModal"
      >
        <CForm>
          <CInput
            :lazy="false"
            :value.sync="$v.form.customer_number.$model"
            :isValid="checkIfValid('customer_number')"
            placeholder="Customer Number"
            autocomplete="given-customer-number"
            invalidFeedback="This is a required field"
          />

          <CInput
            :lazy="false"
            :value.sync="$v.form.ship_to_number.$model"
            :isValid="checkIfValid('ship_to_number')"
            placeholder="Ship To Number"
            autocomplete="given-name"
            invalidFeedback="This is a required field"
          />

          <CInput
            :lazy="false"
            :value.sync="$v.form.ship_to_name.$model"
            :isValid="checkIfValid('ship_to_name')"
            placeholder="Ship To Name"
            autocomplete="given-name"
            invalidFeedback="This is a required field"
          />

          <CInput
            :lazy="false"
            :value.sync="$v.form.address.$model"
            :isValid="checkIfValid('address')"
            placeholder="Address"
            invalidFeedback="This is a required field"
          />

          <CInput
            type="email"
            :lazy="false"
            :value.sync="$v.form.city.$model"
            :isValid="checkIfValid('city')"
            placeholder="City"
            autocomplete="city"
            invalidFeedback="This is a required field and must be valid e-mail address"
          />

          <!-- <v-select 
            v-model="$v.form.state.$model"
            :options="states"
            :isValid="checkIfValid('state')"
            placeholder="State"
            autocomplete="off"
          /> -->

          <select class="form-control" v-model="$v.form.state.$model">
            <option value="">Select State</option>
            <option v-for="val in states" :value="val">{{val}}</option>
          </select>

          <CInput
            type="zip_code"
            style="margin-top:15px;"
            :lazy="false"
            :value.sync="$v.form.zip_code.$model"
            :isValid="checkIfValid('zip_code')"
            placeholder="Zip Code"
            autocomplete="zip_code"
            invalidFeedback="This is a required field"
          />

          <!-- <v-select 
            style='margin-top:15px;'
            v-model="$v.addUserForm.menu_roles.$model"
            :options="['admin', 'user admin', 'rep', 'rep admin']" 
            :isValid="checkIfValid('menu_roles')"
            placeholder="Menu Roles"
          /> -->
        </CForm>
        <template #footer>
          <CButton @click="addModal = false, reset()" color="danger">Cancel</CButton>
          <CButton @click="add()" color="success">Add</CButton>
        </template>
      </CModal>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"

export default {
  name: 'CustomersTable',
  data() {
    return {
      items: [],
      fields: ['customer_number', 'ship_to_name', 'address', 'city', 'state', 'zip_code', 'edit', 'delete'],
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      you: null,
      message: '',
      showMessage: false,
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      addModal: false,
      form: this.getForm(),
      editModal: false,
      roles: null,
      states: [
        "Alabama",
        "Alaska",
        "Alberta",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
      ]
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  mixins: [validationMixin],
  validations: {
    form: {
      customer_number: {
        required
      },
      ship_to_number: {
        required
      },
      ship_to_name: {
        required
      },
      address: {
        required
      },
      zip_code: {
        required
      },
      city: {
        required
      },
      state: {
        required
      }
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  methods: {
    add() {
        let self = this;
        axios.post(   this.$apiAdress + '/api/submitShipTo?token=' + window.localStorage.getItem("api_token"),
          self.form
        )
        .then(function (response) {
            console.log(response)
            self.items.push(response.data)
            self.addModal = false
            self.form = self.getForm()
        }).catch(function (error) {
            // if(error.response.data.message == 'The given data was invalid.'){
            //   self.message = '';
            //   for (let key in error.response.data.errors) {
            //     if (error.response.data.errors.hasOwnProperty(key)) {
            //       self.message += error.response.data.errors[key][0] + '  ';
            //     }
            //   }
            //   self.showAlert();
            // }
        });
    },
    editShipTo(item)  {
      this.form = item
      this.addModal = true
    },
    getForm () {
      return {
        id: '',
        customer_number: "",
        ship_to_number: "",
        ship_to_name: "",
        address: "",
        city: '',
        state: '',
        zip_code: ''
      }
    },
    validate () {
      this.$v.$touch()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field.$model === '')
    },

    reset () {
      this.form = this.getForm()
      this.submitted = false
      this.$v.$reset()
    },
    deleteUser ( id ) {
      let self = this;
      let userId = id;
      axios.post( this.$apiAdress + '/api/users/' + id + '?token=' + localStorage.getItem("api_token"), {
        _method: 'DELETE'
      })
      .then(function (response) {
          self.message = 'Successfully deleted user.';
          self.showAlert();
          self.getData();
      }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
      });
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    getData (){
      let self = this;
      axios.post( this.$apiAdress + '/api/getShipTos?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.items = response.data;
      }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
      });
    },
  },
  mounted: function(){
    this.getData();
  }
}
</script>
